<!--
 * @Author: dengqiuju@baidu.com
 * @Date: 2021-12-06 23:33:46
 * @Description: 线上推广码
-->
<template>
    <div class="ant-card-body">
        <!-- <div class="head-box">
            <div class="table-operator">
                <a-form layout="inline" :model="form" @submit="handleSearch" @submit.native.prevent>
                    <a-form-model-item label="商品类型">
                        <a-select style="width: 200px" v-model="form.type" @change="typeChange">
                            <a-select-option
                                v-for="item in typeList"
                                :key="item.key"
                            >{{ item.labelText }}</a-select-option>
                        </a-select>
                    </a-form-model-item>
                    <a-form-model-item label="商品名称">
                        <a-input v-model="form.agentUserId" placeholder="请输入商品名称">
                            <a-icon slot="prefix" type="search" style="color: rgba(0, 0, 0, 0.25)" />
                        </a-input>
                    </a-form-model-item>
                    <a-form-model-item>
                        <a-button icon="search" type="primary" html-type="submit">搜索</a-button>
                    </a-form-model-item>
                </a-form>
            </div>
        </div> -->
        <div class="content-box">
            <a-table
                :columns="columns"
                :data-source="tableList"
                :pagination="false"
            >
                <div slot="type" slot-scope="type, record">
                    <span v-if="type == 1">课程</span>
                    <span v-else-if="type == 2">会员卡</span>
                </div>
                <div slot="courseType" slot-scope="courseType, record">
                    <span v-if="courseType == 1">伴读课</span>
                    <span v-else-if="courseType == 2">网课</span>
                </div>
                <div slot="action" slot-scope="text, record">
                    <span v-show="isShowShare">
                        <a class="btn-edit mr20" @click="shareEwmClick(record)">分享二维码</a>
                        <a class="btn-edit mr20" @click="shareH5Click(record)">分享链接</a>
                    </span>
                </div>
            </a-table>
        </div>
        <MyPagination :count="count" @showSizeChangeFn="showSizeChangeFn" />
        <!-- 分享的弹框 -->
        <a-modal v-model="modalVisible" :footer="null" width="40%">
            <div class="share">分享</div>
            <a-tabs v-model="shareActiveTabIdx">
                <a-tab-pane v-if="shareActiveTabIdx == 1" key="1" tab="二维码">
                    <div class="share_modal">
                        <div class="share_img">
                            <img :src="ewmImageUrl" alt="二维码" />
                        </div>
                        <div class="share_text">
                            <a-button @click="downImg">下载</a-button>
                            <div style="margin-top: 20px">扫码后转发页面至微信群</div>
                            <div>群内用户可领取邀请码(每人限领一次)</div>
                        </div>
                    </div>
                </a-tab-pane>
                <a-tab-pane v-else-if="shareActiveTabIdx == 2" key="2" tab="H5">
                    <div class="share_modal">
                        <span style="margin-right: 20px">链接</span>
                        <a-input
                            placeholder="H5链接"
                            disabled
                            style="width: 75%"
                            v-model="shareInputVal"
                        />
                        <a-button
                            id="copy_text"
                            :data-clipboard-text="shareInputVal"
                            type="primary"
                            style="margin-left: 20px"
                            @click="handleCopyFun"
                        >
                        复制
                        </a-button>
                    </div>
                </a-tab-pane>
            </a-tabs>
        </a-modal>
    </div>
</template>

<script>
import Clipboard from "clipboard";
import {
    OnlineInvitationCodeListApi,
    ShareEwmCodeApi
} from "@/request/api/marketingCenter";
import MyPagination from "@/components/pagination/MyPagination";
import { throttle, codeFn } from "@/utils/tools";
import { domainName } from "@/config/index";

export default {
    data() {
        return {
            agentUserId: 0,
            typeList: [
                {
                    key: 0,
                    labelText: "全部"
                },
                {
                    key: 2,
                    labelText: "会员卡"
                },
                {
                    key: 1,
                    labelText: "课程"
                }
            ],
            typesMap: {
                1: 'course',
                2: 'memberCard'
            },
            types: {
                course: {
                    key: 1,
                    url: 'h5/dyw/#/home/'
                },
                memberCard: {
                    key: 2,
                    url: 'h5/dyw/#/memberDetail/'
                }
            },
            form: {
                type: 0,
                pageNo: 1,
                pageSize: 20
            },
            count: 0,
            columns: [
                {
                    title: "商品名称",
                    dataIndex: "goodsName",
                    key: "goodsName"
                },
                {
                    title: "商品类型",
                    dataIndex: "type",
                    key: "type",
                    scopedSlots: { customRender: "type" }
                },
                {
                    title: "课节数",
                    dataIndex: "lessonsCount",
                    key: "lessonsCount",
                },
                {
                    title: "课程类型",
                    dataIndex: "courseType",
                    key: "courseType",
                    scopedSlots: { customRender: "courseType" }
                },
                {
                    title: "定价",
                    dataIndex: "price",
                    key: "price",
                    scopedSlots: { customRender: "price" }
                },
                {
                    title: "操作",
                    dataIndex: "action",
                    key: "action",
                    scopedSlots: { customRender: "action" }
                }
            ],
            tableList: [],
            tableLoading: false,
            isShowShare: true,
            modalVisible: false, // 分享弹框是否显示
            shareActiveTabIdx: '1',
            shareInputVal: '',
            ewmImageUrl: "" // 分享弹框的二维码链接
        };
    },
    components: {
        MyPagination
    },
    created() {
        // if (codeFn("/admin/files/getQRCodeBase64")) {
        //     this.isShowShare = true;
        // }
        const { agentUserId } = this.$route.query;
        if (agentUserId) {
            this.agentUserId = agentUserId;
        }
        this.getOnlineInvitationCodeList();
    },
    methods: {
        // 邀请码列表数据
        getOnlineInvitationCodeList() {
            this.tableLoading = true;
            // 管理员要传代理的agentUserId, 代理随便传，只显示自己的
            const agentUserId = this.agentUserId;
            const params = {
                agentUserId,
                ...this.form
            };
            OnlineInvitationCodeListApi(params).then(res => {
                if (res.code == 200) {
                    this.count = res.data.length;
                    const newData = res.data.map(item => {
                        return {
                            key: item.goodsNo,
                            ...item
                        };
                        // return JSON.parse(JSON.stringify(item).replace(/goodsNo/g, "key"));
                    });
                    this.tableList = newData;
                }
                this.tableLoading = false;
            });
        },
        // 分页切换页数
        showSizeChangeFn(pageNo, pageSize) {
            this.form.pageNo = pageNo;
            this.form.pageSize = pageSize;
            throttle(this.getOnlineInvitationCodeList());
        },
        typeChange(v) {
            this.form.type = v;
            this.handleSearch();
        },
        handleSearch() {
            this.getOnlineInvitationCodeList();
        },
        getShareUrl(record) {
            const typeName = this.typesMap[record.type];
            const path = this.types[typeName].url;
            let link = `${domainName}${path}`;
            if (record.type == this.types.course.key) {
                link += `${record.goodsNo}/0`;
            } else {
                link += `${record.goodsNo}`;
            }
            link += `?agentUserId=${record.agentUserId}`;
            return link;
        },
        // 分享二维码的按钮
        shareEwmClick(record) {
            this.shareActiveTabIdx = '1';
            const url = this.getShareUrl(record);
            this.shareInputVal = url;
            const params = {
                text: url,
                height: 300,
                width: 300
            };
            ShareEwmCodeApi(params).then(
                ({ code, data }) => {
                    if (code == 200) {
                        this.modalVisible = true;
                        this.ewmImageUrl = data;
                    }
                }
            );
        },
        // 分享链接按钮
        shareH5Click(record) {
            this.shareActiveTabIdx = '2';
            this.modalVisible = true;
            this.shareInputVal = this.getShareUrl(record);;
        },
        // 分享弹框复制按钮
        handleCopyFun() {
            let clipboard = new Clipboard("#copy_text");
            clipboard.on("success", (e) => {
                message.success("复制成功");
                this.shareModalVisible = false;
                clipboard.destroy(); // 释放内存
            });
            clipboard.on("error", (e) => {
                message.error("复制失败"); // 不支持复制
                this.shareModalVisible = false;
                clipboard.destroy(); // 释放内存
            });
        },
        // 分享的下载按钮
        downImg() {
            const image = new Image();
            // 跨域，解决跨域 Canvas 污染问题
            image.setAttribute("crossOrigin", "anonymous");
            image.onload = function () {
                const canvas = document.createElement("canvas");
                canvas.width = image.width;
                canvas.height = image.height;
                const context = canvas.getContext("2d");
                context.drawImage(image, 0, 0, image.width, image.height);
                const url = canvas.toDataURL("image/png"); // 得到图片的base64编码数据
                const a = document.createElement("a"); // 生成一个a元素
                const event = new MouseEvent("click"); // 创建一个单击事件
                a.download = "分享二维码" || "photo"; // 设置图片名称
                a.href = url; // 将生成的URL设置为a.href属性
                a.dispatchEvent(event); // 触发a的单击事件
            };
            image.src = this.ewmImageUrl;
        },
    }
};
</script>

<style lang="less" scoped>
.mr20 {
    margin-right: 20px;
}

.share_modal {
    display: flex;
    padding-bottom: 10px;
    .share_img {
        margin-left: 30px;
        width: 150px;
        height: 150px;
        img {
            width: 100%;
            height: 100%;
        }
    }
    .share_text {
        margin-left: 50px;
    }
}
</style>